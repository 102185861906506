import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.module.scss';
import IndexPage from './views/IndexPage';


function App() {
  return (
      <Router>
          <Routes>
              <Route path='/' element={<IndexPage />} />
          </Routes>
      </Router>
  );
}

export default App;
