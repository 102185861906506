import React from 'react';
import styles from './IndexPage.module.scss';

const IndexPage = () => {
    return (
        <main className={styles.main}>
            <p>다시 준비중..</p>
            <p><a href='mailto:master@widesoccer.com'>master@widesoccer.com</a></p>
        </main>
    )
}
export default IndexPage;